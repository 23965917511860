import React from 'react';
import Tile from './Tile';
import "../style/Evolution.scss"

function EvoChain({ chain }) {
  if (!chain) return null;

  const fullRand = chain.species.url.split('/')[6];
  const img = `https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${fullRand}.png`;
  const evoles_to = chain.evolves_to?.length ? chain.evolves_to : null;

  return (
    <div className="evoChain">
      <Tile
        key={fullRand}
        img={img}
        name={chain.species.name}
        id={fullRand}
        url={chain.species.url}
        noName={true}
      />
      {evoles_to && (
        <React.Fragment>
          <div className="evolveType">
            {evoles_to.map((item, index) => {
              return (
              <div key={`evolveType-${index}`}>
                {
                  item.evolution_details.map((detail, ind) => {
                    return (<span key={`${detail.trigger.name}-${ind}`}>{detail.trigger.name}</span>)
                  })
                }
              </div>
              )
            })}
          </div>
          <div className="evolveTo">
            {
              evoles_to.map((item, index) => <EvoChain key={`evochain-${index}`} chain={item} />)
            }
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default EvoChain;
