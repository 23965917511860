import React from 'react'
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../style/Tile.scss"

function Tile({img, name, id, noName=false}) {
	return (
		<div className="card" name={name} pokeno={id}> 
			{!noName ? <p>#{id}: <span>{name[0].toUpperCase()+name.slice(1)}</span></p> : null}
      <Link to={`/${name}`}>
				<LazyLoadImage 
					src={img}
					width={144} height={144}
					alt={name}
					title={name}
				/>
      </Link>
		</div>
	);
}

export default Tile;